.application {
  background: rgb(254, 248, 237);
}
.application .titleBody {
  display: flex;
  flex-flow: column;
  padding-bottom: 16px;
}
.application .titleBody h2 {
  margin-bottom: 0;
}
.info-box {
  padding: 32px 16px;
  display: flex;
  flex-flow: column;
}
.info-box .resume {
  display: flex;
  flex-flow: column;
  margin-top: 12px;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #FFE4AF;
  box-sizing: border-box;
  border-radius: 3px;
}
.info-box .application-info .premium-coverage {
  margin-top: 8px;
}
.info-box .company-ic {
  width: 100%;
}
.info-box .quote-value-mo{
  color: var(--text);
}
.info-box .application-info h4 {
  margin-bottom: 0;
}
.info-box .application-info .infoText {
  margin-bottom: 4px;
}
.info-box .submit-app {
  margin: 24px 0;
}
.info-box h5 {
  margin-bottom: 12px;
}
.form {
  margin-top: 40px;
}
.form h5 {
  margin-bottom: 14px;
}
.form .disclaimer {
  margin-top: 24px;
  font-family: 'aktiv-grotesk';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;

}
.valid {
  border-color: #28a745 !important;
}
.valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
}

.input-container input {
  padding: 8px 9px;
  color: var(--text);
  border: 1px solid var(--lighter-grey);
}

.info-box .carrierLogo {
  width: 75px;
  height: 75px;
}

.info-box .MutualofOmaha.carrierLogo {
  width: 66px;
  height: 66px;
  padding: 4.5px;
}

.app-info-wrapper {
  flex-direction: column;
}

@media screen and (min-width: 540px) {
  .info-box .resume {
    padding: 24px;
  }
  .info-box .policy-note .navSecondaryItems {
    min-width: 460px;
  }
}

/* Submit card breakpoint*/
@media screen and (min-width: 753px) {
  .d-submit-none {
    display: none;
  }
  .application-info {
    width: -webkit-fill-available;
  }
  .app-info-wrapper {
    flex-direction: row;
  }
  .app-info-wrapper .horizontal-divider {
    margin: 0 32px;
  }
  .benefits-col {
    min-width: 210px;
  }
  .benefits-col.final-expense h5 {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .info-box {
    padding: 40px 24px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 960px) {
  .application {
    background-repeat: no-repeat;
    background-position: 96% 100%;
  }
  .app-info-wrapper {
    flex-direction: column;
  }
  .info-box {
    padding: 40px 20px 41px;
  }
  .info-box .resume {
    padding: 16px;
  }
  .form {
    margin-top: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
  .policy-wrapper {
    margin: 0 20px;
  }
  .application .titleBody {
    padding-bottom: 32px;
  }
  .info-box {
    flex-direction: row;
  }
  .info-box .policy-note .navSecondaryItems {
    min-width: auto;
  }
  .d-submit-none {
    display: block;
  }
  .benefits-col.final-expense h5 {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1080px) {
  .benefits-col.final-expense h5 {
    margin: 0;
  }
}

@media screen and (min-width: 1120px) {
  .application {
    background-position: 74% 100%;
  }
  .form {
    margin-left: 20px;
    margin-right: 20px;
  }
  .policy-wrapper {
    margin-left: 20px;
    margin-right: 30px;
  }
  .info-box .resume {
    padding: 24px;
  }
  .info-box .col-info {
    max-width: 770px;
  }
  .info-box .policy-note .navSecondaryItems {
    min-width: 441px;
  }
  .info-box {
    max-width: 1120px;
  }
}

@media screen and (min-width: 1280px) {
  .info-box {
    padding: 80px;
  }
  .form {
    margin-left: 30px;
  }
  .info-box .application-info .premium-coverage {
    margin-top: 12px;
  }
  .policy-wrapper {
    margin-left: 0;
  }
  .form {
    margin-right: 0;
  }
  .info-box .resume {
    min-width: 530px;
  }
}

@media screen and (min-width: 1440px) {
  .application {
    background-position: 85% 100%;
  }
}

@media screen and (min-width: 1600px) {
  .application {
    background-size: contain;
  }
}

@media screen and (min-width: 1660px) {

  .application-info {
    width: -webkit-fill-available;
  }

}