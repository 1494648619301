.reviews {
  padding: 40px 16px;
  display: flex;
  flex-flow: column;
}
.reviews .col-wrapper {
  max-width: 1064px;
  margin-left: -8px;
  margin-right: -8px;
}
.reviews .title {
  border-top: 4px solid #FFE4AF;;
}
.reviews .title h3 {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 0;
}
.reviews .review h4 {
  font-size: 21px;
}
.reviews .review {
  justify-content: space-between;
}
.reviews .review .social_circle {
  height: 70px;
  width: 70px;
}
@media screen and (min-width: 768px) {
  .reviews {
    padding: 50px 40px 60px;
  }
  .reviews .col-wrapper {
    margin-left: -14px;
    margin-right: -14px;
  }
  .reviews .title h3 {
    margin-bottom: 4px;
  }
  .reviews .review {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}
@media screen and (min-width: 960px) {
  .reviews {
    align-items: center;
  }
  .reviews .title {
    max-width: 739px;
  }
  .reviews .col-wrapper {
    margin-left: -22px;
    margin-right: -22px;
  }
  .reviews .review {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
}
@media screen and (min-width: 1120px) {
  .reviews {
    padding-top: 60px;
    padding-bottom: 84px;
  }
  .reviews .title {
    max-width: 961px;
  }
  .reviews .title h3 {
    margin-top: 36px;
    margin-bottom: 8px;
  }
  .reviews .col-wrapper {
    margin-left: -14px;
    margin-right: -14px;
  }
  .reviews .review {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}