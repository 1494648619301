/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .modal-dialog h4 {
        font-size: 17px;
    }
}}

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm){ 
@supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    .modal-dialog h4 {
        font-size: 17px;
    }
}}

/* Safari 6.1-10.0 (but not 10.1) */
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
@media {
    .modal-dialog h4 {
        font-size: 17px;
    }
}}

.modal-dialog {
    max-width: 500px;
    margin: 50% 16px;
}
.modal.fade .modal-dialog {
    transition: transform .2s ease-in-out;
}
.modal-content {
    background: #FAFAFA;
    border: 1px solid #D6D6D6;
    box-sizing: border-box;
    border-radius: 4px;
}

.modal-header {
    border-bottom: none;
    padding-top: 2.563rem;
}

.modal-body {
    padding: 0;
}

.modal-footer {
    display: block;
    border-top: none;
    padding-bottom: 2.25rem;
    margin: 0;
    padding-top: 0px;
}

.modal-dialog .px-5 {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.modalCross {
    position: absolute;
    right: 11.08px;
    top: 10px;
}

.modal-content h4 {
    color: #161616;
    margin-bottom: 0px;
    text-align: center;
}

.modal-footer > * {
    margin: 0;
}

.updateCovBtn {
    width: 100%;
}

.coverageModal .modal-header {
    padding-bottom: 24px;
}

@media screen and (min-width: 532px){
    .modal-dialog {
        margin: 30vh auto;
    }
}

@media screen and (min-width: 1120px){
    .modal-dialog {
        max-width: 500px;
    }

    .modal-dialog h4 {
        font-size: 20px;
    }

    .modal-dialog .px-5 {
        padding-right: 48px !important;
        padding-left: 48px !important;
    }
}