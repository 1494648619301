.form-indicator {
  width: 100%;
  font-family: 'aktiv-grotesk';
  font-style: normal;
  font-weight: 400;
  text-align: center;
  max-width: 600px;
}
.form-indicator .bar-frame {
  border-radius: 12px;
  background-color: var(--lightest-blue-green);
  font-size: 13px;
  line-height: 17px;
  height: 23px;
  color: transparent;
  padding-right: 12px;
  overflow: hidden;
}
.form-indicator .bar-frame img {
  width: 5.1px;
}
.form-indicator .bar-frame .items-wrapper {
  display: flex;
  z-index: 10;
}
.form-indicator .bar-frame .items-wrapper > div {
  padding: 3px 2px;
}
.form-indicator .bar-frame .items-wrapper > div.not-mac-os {
  padding: 4px 2px 2px;
}
.form-indicator .bar-frame .progress-wrapper {
  position: absolute;
  top: 0;
  left: 0%;
  display: flex;
  z-index: 0;
  width: 98%;
}
.form-indicator .bar-frame .progress-wrapper img {
  height: 23px;
}
.form-indicator .bar-frame .progress {
  height: 23px;
  background-color: var(--deep-blue-green);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: width 300ms ease-in-out;
}
.form-indicator .bar-frame img {
  display: block;
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
}
.form-indicator .bar-frame .checked-question {
  position: relative;
  display: inline-block;
  transition: all 500ms ease-in-out;
  color: white;
  cursor: pointer;
}
.form-indicator .bar-frame .checked-question:hover {
  font-weight: 500;
}
.form-indicator .bar-frame .checked-question.gender {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.form-indicator .form-responses {
  font-size: 14px;
  line-height: 18px;
  color: var(--text);
  margin: 6px 0 0;
  padding-right: 12px;
  height: 18px;
}
.form-indicator .form-responses > div {
  padding: 0 2px;
  transition: all 300ms ease;
  color: transparent;
}
.form-indicator .form-responses .fade-in {
  color: var(--text);
}
.form-indicator .form-responses .fade-out {
  color: transparent;
}
.form-indicator .bar-frame .gender,
.form-indicator .form-responses .response-gender {
  min-width: 20%;
}
.form-indicator .bar-frame .age,
.form-indicator .form-responses .response-age {
  min-width: 15%;
}
.childrenApplication .form-indicator .bar-frame .age,
.childrenApplication .form-indicator .form-responses .response-age {
  min-width: 33%;
}
.form-indicator .bar-frame .state,
.form-indicator .form-responses .response-state {
  min-width: 15%;
}
.childrenApplication .form-indicator .bar-frame .state,
.childrenApplication .form-indicator .form-responses .response-state {
  min-width: 33%;
}
.form-indicator .bar-frame .tobacco,
.form-indicator .form-responses .response-tobacco {
  min-width: 24%;
}
.form-indicator .bar-frame .coverage,
.form-indicator .form-responses .response-coverage {
  min-width: 26%;
}

.childrenApplication .form-indicator .bar-frame .coverage,
.childrenApplication .form-indicator .form-responses .response-coverage {
  min-width: 33%;
}

@media screen and (min-width: 480px) {
  .form-indicator .bar-frame .gender,
  .form-indicator .form-responses .response-gender {
    min-width: 19%;
  }
  .form-indicator .bar-frame .age,
  .form-indicator .form-responses .response-age {
    min-width: 25%;
  }
  .childrenApplication .form-indicator .bar-frame .age,
  .childrenApplication .form-indicator .form-responses .response-age {
    min-width: 33%;
  }

  .form-indicator .bar-frame .state,
  .form-indicator .form-responses .response-state {
    min-width: 15%;
  }
  
  .childrenApplication .form-indicator .bar-frame .state,
  .childrenApplication .form-indicator .form-responses .response-state {
    min-width: 33%;
  }

  .form-indicator .bar-frame .tobacco,
  .form-indicator .form-responses .response-tobacco {
    min-width: 19%;
  }
  .form-indicator .bar-frame .coverage,
  .form-indicator .form-responses .response-coverage {
    min-width: 22%;
  }
  .childrenApplication .form-indicator .bar-frame .coverage,
  .childrenApplication .form-indicator .form-responses .response-coverage {
    min-width: 33%;
  }
}

@media screen and (min-width: 768px) {
  .form-indicator .bar-frame {
    padding-right: 14px;
  }
}