header {
  height: 69px;
  background: #FFFFFF;
}

header .contact span {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: var(--deep-grey);
}

header .contact .schedule span {
  font-size: 9px;
}

header .contact img {
  height: 14px;
  width: 14px;
  margin-right: 0.25rem;
  margin-top: 1px;
}

header .contact a {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;

  text-align: center; 
  text-decoration: none !important;
  color: var(--deep-blue-green);
  padding-bottom: 0px;
}

header .contact .phoneWrapper a:hover {
  color: rgba(108,117,125,.8);
}

header .contact .phoneWrapper {
  margin: 1px auto;
}

header figure img {
  width: 122.2px;
}


header nav {
  display: flex;
}

header .contact {
  max-width: 162px;
}


@media screen and (min-width: 768px) {
  header {
   height: 72px;
  }

  header figure img {
    width: 152.76px;
  }

}

@media screen and (max-width: 767px) {
  .sm-top {
    padding-top: 10px;
  }

  .justify-content-sm-start {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 810px) {
  header.content {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media screen and (min-width: 1120px) {
  header .contact a {
    font-size: 15px;
  }

  header .contact .phoneWrapper {
    margin: 5px auto;
  }

  /* Safari 11+ */
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    header .contact a {
      font-size: 16px;
    }
  }}

  /* Safari 10.1 */
  @media not all and (min-resolution:.001dpcm){ 
  @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    header .contact a {
      font-size: 16px;
    }
  }}

  /* Safari 6.1-10.0 (but not 10.1) */
  @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
  @media {
    header .contact a {
      font-size: 16px;
    }
  }}

}

@media screen and (min-width: 1600px) {
  header.content {
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}