nav {
  width: 100%;
}
nav .number-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--med-light-grey);
  flex: 1;
}

nav .number-container img {
  height: 24px;
  width: 24px;
  z-index: 3;
}

nav .number-container p:last-child {
  font-size: 15px;
}

nav .selected .label,
nav .completedStep .label {
  color: var(--deep-grey);
}

.number-container::before {
  position: absolute;
  content: "";
  border-bottom: 1px solid var(--med-light-grey);
  width: 100%;
  top: 12px;
  left: -50%;
  z-index: 1;
}

.number-container::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid var(--med-light-grey);
  width: 100%;
  top: 12px;
  left: 50%;
  z-index: 2;
}

.completedStep::after,
.completedStep::before,
.currentStep::before {
  border-bottom: 1px solid var(--deep-blue-green);
}

.number-container:first-child::before {
  content: none;
}
.number-container:last-child::after {
  content: none;
}

@media screen and (min-width: 768px){
  .progressBarContainer {
    max-width: 375px;
    margin: 0 auto;
  }

  .mdProggressBarContainer {
    padding-top: 12px;
  }
}