.titles h2 {
  font-size: 20px;
}
.titles p {
  font-size: 16px;
  line-height: 1.2;
  word-spacing: 2px;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .titles h2 {
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .titles h2 {
    font-size: 30px;
  }
  .titles p {
    font-size: 18px;
  }
}
