body {
  margin: 0;
  padding: 0;
  font-family: "aktiv-grotesk";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 130%;
  color: var(--text);
}
p {
  margin-bottom: 4pt;
}

body h1 {
  font-family: 'p22-mackinac-pro', sans-serif;
  font-size: 34px;
  font-weight: bold;
  line-height: 130%;
}

body h2 {
  font-family: 'p22-mackinac-pro', sans-serif;
  font-size: 24px;
  line-height: 130%;
  font-weight: 700;
}

body h3 {
  font-family: 'p22-mackinac-pro', sans-serif;
  font-weight: bold;
  font-size: 21px;
  line-height: 130%;
}

body h5 {
  font-family: 'aktiv-grotesk';
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
} 
body .titleBody h5 {
  font-weight: 400;
}

body h4,
.detailsHighlight {
  font-family: 'p22-mackinac-pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  font-size: 18px;
}

.infoText {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.quoteBtn {
  background: var(--goldenish-yellow);
  color: var(--deep-grey);
  width: 114px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 14px;
  border-radius: 1px;
  cursor: pointer;
}

.quoteBtn:hover {
  transition: all ease-in-out .2s;
  background: var(--button-background-yellow);
}

.border {
  border: 1px solid rgb(155, 155, 155) !important;
}
.h-38 {
  height: 38px;
  display: -webkit-inline-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bolder {
  font-weight: 500;
}
.ios-bolder {
  font-weight: 900;
}
.sort-bolder {
  font-weight: bold;
}
.fields-required {
  font-style: italic;
  margin-top: 15px;
}
.pointer {
  cursor: pointer;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  margin: 0;
}
.white-bg {
  background-color: #fff;
}
.f-12 {
  font-size: 12px;
  line-height: 1.2;
  word-spacing: 2px;
}
.f-14 {
  font-size: 14px;
  line-height: 1.2;
  word-spacing: 2px;
}
.f-16 {
  font-size: 16px;
  line-height: 1.2;
  word-spacing: 2px;
}

.d-flex {
  display: -webkit-inline-box;
  display: flex;
}
.justify-content-center {
  -webkit-box-pack: center;
}
.align-items-center {
  -webkit-box-align: center;
}
span.react-numeric-input {
  width: 100%;
}

.btn {
  cursor: pointer;
}

.h-15 {
  height: 15px;
}

.nopadding {
  padding-left: 0px;
  padding-right: 0px;
}

.mtAgentContainer {
  margin-top: 2rem;
}

.pLeft0 {
  padding-left: 0px;
}

.mSides0 {
  margin-right: 0;
  margin-left: 0;
}

.cPointer {
  cursor: pointer;
}

.content  {
  padding-left: 16px;
  padding-right: 16px;
}

.edit-bar {
  margin: 20px 0;
}

.navMainItems {
  font-size: 15px;
  font-weight: bold;
}

.navSecondaryItems {
  font-size: 15px;
  font-weight: 500;
}

.client-info .navSecondaryItems{
  font-family: 'aktiv-grotesk';
}

.mt-2rem {
  margin-top: 2rem;
}

.mb-15rem {
  margin-bottom: 1.5rem;
}

.my-15rem {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.card .pl2,
.quote .pl2 {
  margin-left: 0.125rem;
}

.quote h3 {
  margin-bottom: 0px;
}

.ml-05rem {
  margin-left: 0.5rem;
}

.quote-value-mo {
  font-family: 'aktiv-grotesk', sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: var(--bright-peachy);
  margin-left: 2px;
  line-height: 20px;
}

.green-link-with-arrow {
  font-family: 'aktiv-grotesk', sans-serif;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: var(--top-title-background);
  display: flex;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0.01em;
}
.green-link-with-arrow:hover {
  color: var(--top-title-background);
  text-decoration: none;
  opacity: 0.7;
}
.green-link-with-arrow:hover > img {
  margin-left: 12px;
  transition: all ease-in-out .2s;
}
.green-link-with-arrow img {
  margin-left: 8px;
}

:root {
  --card-background: #FFFFFF;
  --quotes-background:#FFFAF1;
  --top-title-background: #006D6F;
  --text: #161616;
  --deep-grey: #474747;
  --light-grey: #D6D6D6;
  --med-light-grey: #BEBEBE;
  --lighter-grey: #EBEBEB;
  --lightest-grey: #FAFAFA;
  --deep-blue-green: #006D6F;
  --light-blue-green: #4CAB9A;
  --lightest-blue-green: #DEF1F1;
  --golden-yellow: #F8AE1F;
  --goldenish-yellow: #FEC963;
  --button-hover-yellow: #FFCE6F;
  --button-background-yellow: #FFE4AF;
  --yellow-cream: #FFF4DE;
  --lightest-cream: #FFFAF1;
  --bright-peachy: #FF554A;
  --button-background-peach: #FF756C;
  --button-background-peach-hover: #FE938C;
  --error-red: #FF2A1D;
  --periwinkle: #654CC8;
  --hovered-blue-green: #C9DEDE;
  --medium-gray: #767676;
}


.p-relative {
  position: relative;
}

.g-2, .gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: 0.5rem;
}

.fw-700 {
  font-weight: 700;
}

.fw-medium, .fw-500 {
  font-weight: 500 !important;
}

.fs-generalinfo-disclaimer {
  font-size: 13px;
  line-height: 140%;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 8px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  line-height: 1;
  left: 8px;
  margin: 0;
  background-color: white;
  padding: 2px;
  color: var(--deep-grey);
}

.input-container .filled,
.input-container:focus-within label {
  transform: translate(0, -7px) scale(0.85);
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.input-container input:focus {
  border-color: var(--light-grey);
  box-shadow: none;
}

.input-container .invalid-feedback {
  margin-top: 0;
}
.input-container 
.small {
  font-size: 20px !important;
}

.fs-18 {
  font-size: 18px;
}

.lh-26 {
  line-height: 26px;
}

.w-90 {
  width: 90%;
}

.fs-15 {
  font-size: 15px;;
}

.c-pointer {
  cursor: pointer;
}

.fs-10 {
  font-size: 10px;
}

.fs-13 {
  font-size: 13px;
}

.p-absolute {
  position: absolute;
}

.mt-6rem {
  margin-top: 6rem;
}

.fw-600 {
  font-weight: 600;
}

.mw-400 {
  max-width: 400px;
}

.sm-break-together {
  display: inline-block;
}

@media screen and (min-width: 540px){
  body h1 {
    font-size: 34px;
  }

  .benefitsWrapper {
    margin: 0 auto 1.9rem;
  }

  .mt-2rem {
    margin-top: 2.5rem;
  }
}

@media screen and (min-width: 648px) {
  .content  {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (min-width: 768px){
  .details .titleBody h5 {
    margin: 0 auto !important;
    max-width: 430px !important;
  }

  .application .titleBody h5 {
    margin: 0 auto !important;
    max-width: 610px !important;
    margin-top: 8px !important;
  }
  .md-lb-after::after {
    content: "\a";
    white-space: pre;
  }
}

@media screen and (min-width: 810px) {
  .content  {
    padding-left: 24px;
    padding-right: 12px;
  }
}

@media screen and (min-width: 1120px){
  body h1 {
    font-size: 48px;
    line-height: 122%;
  }

  body h2 {
    font-size: 33px;
  }

  body h3 {
    font-size: 26px;
  }

  body h4 {
    font-size: 20px;
  }

  .mt-2rem {
    margin-top: 3.75rem;
  }
}

@media screen and (min-width: 1280px){
  .mt-2rem {
    margin-top: 5rem;
  }
  .application .titleBody h5 {
    max-width: 630px !important;
  }
}
