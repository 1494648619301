.quoting-logos-mobile {
    margin-top: 40px;
}
.quoting-logos-mobile .max-400 {
    max-width: 800px;
}

.quoting-logos-mobile .selected-logos {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.quoting-logos-mobile .selected-logos > div {
    flex-basis: 20%;
    text-align: center;
    max-width: 25%;
    margin: 12px;
}

.quoting-logos-mobile .selected-logos > div img {
    width: 100%;
    max-height: 68px;
}

.quoting-logos-mobile hr:not([size]) {
    height: 1px;
}

.quoting-logos-mobile hr {
    margin: 1.5rem 0;
    color: inherit;
    background-color: var(--light-grey);   
    border: 0;
    opacity: 0.25;
}

.quoting-logos-mobile hr {
    opacity: 1;
}

.quoting-logos-mobile img {
    width: 100%;
    max-height: 68px;
}

.quoting-logos-mobile .aig img {
    max-width: 75px;
}

.quoting-logos-mobile .aetna img {
    max-width: 86.92px;
}

.quoting-logos-desktop .aside-quoting {
    max-width: 282px;
}
.quoting-logos-desktop .aside {
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
}

.ms-auto {
    margin-left: auto !important;
}

.aside-quoting .aside-body {
    border: 1px solid var(--button-background-yellow);
    border-radius: 3px;
    background: #FFFFFF;
}

.aside-body .selected-logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.aside-quoting .selected-logos > div {
    flex-basis: auto;
    text-align: center;
    max-width: 50%;
    margin: 12px;
}

.aside-quoting .selected-logos > div img {
    width: 100%;
    max-height: 84px;
}
.aside-body .selected-logos img {
    mix-blend-mode: luminosity;
}

.quoting-logos-mobile .gerber-baby {
    width: 35%;
}

.quoting-logos-mobile .gerber-text {
    width: 65%;
}

.quoting-logos-mobile .first-row {
    margin-right: auto;
    margin-left: auto;
}
.quoting-logos-mobile .second-row {
    margin-right: auto;
    margin-left: auto;
}

.quoting-logos-mobile .USAA {
    max-width: 42px;
}


.quoting-logos-mobile .Aflac {
    max-width: 119px;
}

.quoting-logos-mobile .AAA {
    max-width: 51px;
}

.quoting-logos-mobile .CP {
    max-height: 51px;
}

.quoting-logos-mobile .AIGTrustedInsurers {
    max-width: 104px;
}

@media (min-width: 48em) and (max-width: 69.99em) {
    .aside-quoting .selected-logos > div {
        max-width: 100%;
        flex-basis: 100%;
    }    

}

@media screen and (min-width: 540px){
    .quoting-logos-mobile {
      margin-top: 52px;
    }
}

@media screen and (min-width: 576px){
    .quoting-logos-mobile .USAA {
        max-width: 67px !important;
    }  
    
    .quoting-logos-mobile .AAA {
        max-width: 79px;
        max-height: auto;
    }
}

@media (min-width: 768px) {
    .mb-sm-0 {
        margin-bottom: 0 !important;
    }

    .details .c-blog-content--inner {
        min-width: 398px;
    }
    .aside-quoting .selected-logos .gerber .gerber-baby {
        width: 84px;
    }
    .aside-quoting .selected-logos .gerber .gerber-text {
        width: 140px;
    }

    .quoting-logos-mobile img {
        max-height: 84px;
    }
    .quoting-logos-mobile .px-md-2ndline {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }  
    .max-400 {
        max-width: 800px !important;
    }
}

@media (min-width: 960px) {
    .quoting-logos-desktop .aside-quoting {
        max-width: 298px;
    }

    .details .c-blog-content--inner {
        max-width: 457px;
    }

    .c-quoting-blob {
        padding-right: 52.5px !important;
        padding-left: 52.5px !important;
    }

    .quoting-logos-desktop .aside {
        margin-left: 100px;
    }

    .container-quoting {
        max-width: 855px;
        margin: 0 auto;
    }
}   

@media (min-width: 1120px) {
    .quoting-logos-desktop .aside-quoting {
        max-width: 417px;
    }

    .aside-quoting .selected-logos .gerber {
        max-width: 224px;
    }

    .aside-quoting .selected-logos .gerber .gerber-baby {
        width: 84px;
    }
    .aside-quoting .selected-logos .gerber .gerber-text {
        width: 140px;
    }

    @-moz-document url-prefix() {
        .aside-quoting .selected-logos > .transamericaDesk img {
            width: 185px !important;
        }

        .aside-quoting .selected-logos > .aetnaDesk img {
            width: 138px !important;
        }

        .aside-quoting .selected-logos > .aetnaDesk img {
            width: 138px !important;
        }

        .aside-quoting .selected-logos > .mooDesk img {
            width: 190px !important;
        }

        .aside-quoting .selected-logos > .forestersDesk img {
            width: 141px !important;
        }

        .aside-quoting .selected-logos > .aigDesk img {
            width: 107px !important;
        }
    }

    /* Safari 11+ */
    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        .aside-quoting .selected-logos > .transamericaDesk img {
            width: 185px !important;
        }   

        .aside-quoting .selected-logos > .aetnaDesk img {
            width: 138px !important;
        }   

        .aside-quoting .selected-logos > .aetnaDesk img {
            width: 138px !important;
        }   

        .aside-quoting .selected-logos > .mooDesk img {
            width: 190px !important;
        }   

        .aside-quoting .selected-logos > .forestersDesk img {
            width: 141px !important;
        }   

        .aside-quoting .selected-logos > .aigDesk img {
            width: 107px !important;
        }

        .aside-quoting .selected-logos > .gerberDesk,
        .aside-quoting .selected-logos > .nationwideDesk {
            max-width: 37% !important;
        }
        .aside-quoting .selected-logos .gerber .gerber-baby {
            width: 75px;
        }
        .aside-quoting .selected-logos .gerber .gerber-text {
            width: 110px;
        }
    }}  

    /* Safari 10.1 */
    @media not all and (min-resolution:.001dpcm){ 
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
        .aside-quoting .selected-logos > .transamericaDesk img {
            width: 185px !important;
        }   

        .aside-quoting .selected-logos > .aetnaDesk img {
            width: 138px !important;
        }   

        .aside-quoting .selected-logos > .aetnaDesk img {
            width: 138px !important;
        }   

        .aside-quoting .selected-logos > .mooDesk img {
            width: 190px !important;
        }   

        .aside-quoting .selected-logos > .forestersDesk img {
            width: 141px !important;
        }   

        .aside-quoting .selected-logos > .aigDesk img {
            width: 107px !important;
        }

        .aside-quoting .selected-logos > .gerberDesk,
        .aside-quoting .selected-logos > .nationwideDesk {
            max-width: 37% !important;
        }
        .aside-quoting .selected-logos .gerber .gerber-baby {
            width: 75px;
        }
        .aside-quoting .selected-logos .gerber .gerber-text {
            width: 110px;
        }
    }}  

    /* Safari 6.1-10.0 (but not 10.1) */
    @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
    @media {
        .aside-quoting .selected-logos > .transamericaDesk img {
            width: 185px !important;
        }   

        .aside-quoting .selected-logos > .aetnaDesk img {
            width: 138px !important;
        }   

        .aside-quoting .selected-logos > .aetnaDesk img {
            width: 138px !important;
        }   

        .aside-quoting .selected-logos > .mooDesk img {
            width: 190px !important;
        }   

        .aside-quoting .selected-logos > .forestersDesk img {
            width: 141px !important;
        }   

        .aside-quoting .selected-logos > .aigDesk img {
            width: 107px !important;
        }

        .aside-quoting .selected-logos > .gerberDesk,
        .aside-quoting .selected-logos > .nationwideDesk {
            max-width: 37% !important;
        }
        .aside-quoting .selected-logos .gerber .gerber-baby {
            width: 75px;
        }
        .aside-quoting .selected-logos .gerber .gerber-text {
            width: 110px;
        }
    }}

}
