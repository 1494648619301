.CMTooltipWrapper {
    padding: 16px 16px 20px;
    background-color: var(--card-background);
    font-weight: 400;
    color: var(--text);
    width: 101%;
    position: absolute;
    border: 1px solid #d6d6d6 !important;
    box-shadow: 0 1px 8px 1px #d6d6d6 !important;
    border-radius: 1px !important;
    z-index: 100;
    top: 50px;
}

.infoIconWrapper::after {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: transparent transparent var(--card-background) transparent;
    border-width: 10px;
    top: 15px; /* Adjust this value to control the distance between the caret and the tooltip */
    right: 5%;
    transform: translateX(50%);
    opacity: 0; /* Initially hide the caret */
    z-index: 200;
  }
  
  .infoIconWrapper::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: transparent transparent #d6d6d6 transparent; /* Set the border color */
    border-width: 11px; /* Adjust the border width to cover the original arrow */
    top: 12px; /* Adjust this value to control the distance between the caret and the tooltip */
    right: 5%;
    transform: translateX(50%);
    opacity: 0; /* Initially hide the border */
  }
  
  .infoIconWrapper:hover::after,
  .infoIconWrapper:hover::before {
    opacity: 1; /* Show the caret and border on hover */
  }

  @media screen and (min-width: 385px) {
    .quote .CMTooltipWrapper {
      top: 30px;
    }
  }

  @media screen and (min-width: 648px) {
    .quote .CMTooltipWrapper {
      top: 40px;
    }
  }

  @media screen and (min-width: 810px) {
    .quote .CMTooltipWrapper {
      top: 30px;
    }
  }

  @media screen and (min-width: 960px) {
    .quote .CMTooltipWrapper {
        top: 43px;
      }
  }