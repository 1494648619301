.deep-quote-exploration h2 {
    color: #FFFFFF
}

.deep-quote-exploration figure {
    margin-top: 32px;
    margin-bottom: 32px;
}

.deep-quote-exploration .buttonsWrapper {
    margin-top: 24px;
    padding-bottom: 48px;
}

.deep-quote-exploration-title {
    background-color: var(--top-title-background);
    width: 100%;
    height: 77px;
}

.deep-quote-exploration .back-btn-wrapper {
    order: 2;
}
.deep-quote-exploration .edit-btn {
    order: 1;
    margin-bottom: 18px;
}

@media screen and (min-width: 540px) {
    .deep-quote-exploration .edit-btn {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 828px){
    .deep-quote-exploration .content {
        max-width: 780px;
        margin: 0 auto;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media screen and (min-width: 960px){
    .deep-quote-exploration-title {
        height: 109px !important;
    }
}

@media screen and (min-width: 1120px){
    .deep-quote-exploration-title {
        height: 122px !important;
    }
}
