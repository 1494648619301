.gender-selected {
  background-color: rgb(39, 14, 13) !important;
  color: white;
}
.smoke-selected {
  background-color: rgb(39, 14, 13) !important;
  color: white;
}

/* arrows */

select.styled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../../assets/select_arrow.png');
  background-position: right;
  background-repeat: no-repeat;
  padding: 0.5em 3.5em 0.5em 1em;
  text-indent: 0.01px;
  text-overflow: "";
  select::-ms-expand { display: none; }
}


.error {
  color: #dc3545;
  height: 100%;
}

.errWrapper {
  height: 22.09px;
}

.quizErr {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.border-error {
  border-color: #dc3545 !important;
}

.progressBarWrapper {
  background-color: var(--lightest-blue-green);
  height: 63px;
}

.progressBarWrapper.generalInformation {
  background-color: var(--lightest-cream);
  padding-top: 32px;
}

.details .topTitle {
  padding-top: 16px;
  padding-bottom: 16px; 
  height: auto;
}

.topTitleContent {
  max-width: 543px;
  margin-left: auto;
  margin-right: auto;
}

.details .blobWrapper {
  overflow-y: hidden;
}

.details .c-quoting-blob,
.childrenApplication .c-quoting-blob {
  background: var(--quotes-background);
  background-image: url(../../../assets/Details/bg-blob-quoting.svg);
  background-position: center 90%;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.py-6 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.details .title {
  color: var(--deep-blue-green);
  margin-bottom: 28px;
  font-size: 21px;
  line-height: 130%;
}

.details .c-blog-content--inner {
  max-width: 710px;
}

.details .form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.details .form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.details label {
  color: var(--deep-grey);
  display: inline-block;
  margin-bottom: 0px;
}

.details .form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTEuNSIgZmlsbD0id2hpdGUiIHN0cm9rZT0iIzQ3NDc0NyIvPgo8ZWxsaXBzZSBjeD0iMTEuOTk5OSIgY3k9IjEyLjAwMDEiIHJ4PSI3LjcxNDI5IiByeT0iNy43MTQyOSIgZmlsbD0iIzQ3NDc0NyIvPgo8L3N2Zz4K");
  border: none;
}

.details .form-check-input:active {
  filter: brightness(90%);
}
.details .form-check-input[type=radio] {
  border-radius: 50%;
}
.details .form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.fs-14 {
  font-size: 14px;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.details .form-select {
  border-radius: 0;
  color: var(--deep-grey);
  font-size: 14px;
}

.details .form-check-input {
  width: 24px;
  height: 24px;
  margin-top: 0.25em;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 18px;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.form-select.not-mac-os {
  padding-top: 0.5rem;
}

.mt-gutter-y {
  margin-top: var(--bs-gutter-y);
}

.form-control, .form-select {
  border-radius: 0;
  color: var(--deep-grey);
  font-size: 14px;
}

.details .quoteBtn {
  padding: 16px 30px;
  height: auto;
}

.form-check-inline .form-check-input {
  margin-right: 8px;
  cursor: pointer;
}

.details .input-container input {
  padding: 0.375rem 0.75rem !important;
  color: var(--text) !important;
  border: 1px solid var(--light-grey) !important;
}

.details .input-container input.borderError {
  border: 1px solid #dc3545 !important;
}

.details .navButtonsWrapper,
.childrenApplication .navButtonsWrapper {
  height: 22.08px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 30px;
}

.details .prevQ,
.childrenApplication .prevQ {
  color: var(--deep-grey);
}

.details .continue,
.childrenApplication .continue {
  color: var(--deep-blue-green);
}

.details .navImg:nth-child(2),
.childrenApplication .navImg:nth-child(2) {
  width: 18px;
  height: 18px;
}

.details .navImg:first-child,
.childrenApplication .navImg:first-child {
  width: 14px;
  height: 14px;
}

.details .backQ,
.childrenApplication .backQ {
  width: 169px;
}

.details .continueQ,
.childrenApplication .continueQ {
  width: 101px;
}


.details .backQ img,
.childrenApplication .backQ img {
  margin-right: 8px;
}

.details .continueQ img,
.childrenApplication .continueQ img {
  margin-left: 8px;
}

.details .backQ:hover img,
.childrenApplication .backQ:hover img {
  margin-right: 13.3px;
  transition: all .2s ease-in-out;
}

.details .continueQ.disabled,
.childrenApplication .continueQ.disabled {
  opacity: 0.4;
}

.details .disabled .continue,
.childrenApplication .disabled .continue {
  cursor: default;
}

.details .continueQ.enabled,
.childrenApplication .continueQ.enabled {
  transition: all .2s ease-in-out;
}

@media screen and (min-width: 768px){
  .details .backQ:hover,
  .details .continueQ.enabled:hover,
  .childrenApplication .backQ:hover,
  .childrenApplication .continueQ.enabled:hover {
    opacity: .7;
  }
  .details .continueQ.enabled:hover img,
  .childrenApplication .continueQ.enabled:hover img {
    margin-left: 13.3px;
    transition: all .2s ease-in-out;
  }  
}


@media screen and (min-width: 540px){
  .details .topTitle {
    text-align: center;
  }

  .details .topTitle {
    padding-left: 24px;
    padding-right: 24px;
  }

  .details .c-quoting-blob .content {
    padding-right: 24px;
    padding-left: 24px;
  }

  .details .c-quoting-blob,
  .childrenApplication .c-quoting-blob{
    background-image: url(../../../assets/Details/bg-blob-quoting540.svg);
  }

  .details .topTitle {
    padding-top: 24px;
    padding-bottom: 24px; 
  }  
}

@media screen and (min-width: 768px){
  .details .c-quoting-blob,
  .childrenApplication .c-quoting-blob {
    background-image: url(../../../assets/Details/bg-blob-quoting768.svg);
  }
}

@media screen and (min-width: 960px){
  .details .topTitle {
    padding-top: 40px;
    padding-bottom: 40px; 
  }

  .py-6 {
    padding-top: 48px !important;
  }

  .details .c-quoting-blob,
  .childrenApplication .c-quoting-blob {
    background-image: url(../../../assets/Details/bg-blob-quoting960.svg);
  }
}

@media screen and (min-width: 1120px){
  .details .c-quoting-blob,
  .childrenApplication .c-quoting-blob {
    background-image: url(../../../assets/Details/bg-blob-quoting1120.svg);
  }
}

@media screen and (min-width: 1280px){
  .details .c-quoting-blob,
  .childrenApplication .c-quoting-blob {
    background-image: url(../../../assets/Details/bg-blob-quoting1280.svg);
  }
}

@media screen and (min-width: 1440px){
  .details .c-quoting-blob,
  .childrenApplication .c-quoting-blob {
    background-image: url(../../../assets/Details/bg-blob-quoting1440.svg);
  }
}

@media screen and (min-width: 1600px){
  .details .c-quoting-blob,
  .childrenApplication .c-quoting-blob {
    background-image: url(../../../assets/Details/bg-blob-quoting1600.svg);
  }
}