.confirmation .topTitleWrapper {
  background-color: var(--top-title-background);
}
.confirmation .topTitle {
  height: fit-content;
  text-align: center;
  padding: 40px 0;
  max-width: 780px;
}
.confirmation .wrapper {
  padding: 0;
}
.confirmation .titleBody h5 {
  margin: 0;
}
.confirmation .wrapper {
  display: flex;
  flex-flow: column;
  padding: 32px 16px;
  background-color: var(--lightest-cream);
}
.confirmation .wrapper .confirmation-ic {
  height: 80px;
}
.confirmation .wrapper .text-wrapper {
  font-size: 18px;
  margin: 0 auto 40px;
  max-width: 780px;
}
.confirmation .wrapper .text-wrapper .main-text {
  font-weight: 500;
}
.confirmation .wrapper .text-wrapper .italic-17-600 {
  font-size: 17px;
  font-style: italic;
  font-weight: 600;
}
.confirmation .wrapper .text-wrapper .circleNumberIc {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--deep-blue-green);
  text-align: center;
  align-content: center;
  color: white;
  margin-bottom: 0;
  margin-right: 16px;
}
.confirmation .underline-text {
  text-decoration-line: underline;
}
.confirmation .wrapper .buttons-wrapper {
  display: flex;
  flex-flow: column;
  max-width: 278px;
}
.confirmation .content .buttons-wrapper .editInfoBtn {
  width: 250px;
}

.confirmation .exitIntentPopup {
  position: fixed;
  top: 0;
  z-index: 4;
  background: var(--lightest-cream);
  padding: 48px 16px 48px 16px;
  opacity: 0;
  animation: fadeInOut 0.8s ease-in-out forwards; /* Apply animation */
}

@keyframes fadeInOut {
  0% {
    opacity: 0; /* Start with opacity 0 */
    transform: scale(0.5); /* Start with smaller scale */
  }
  50% {
    opacity: 1; /* Increase opacity to 1 halfway through */
    transform: scale(1.1); /* Increase scale halfway through */
  }
  100% {
    opacity: 1; /* Keep opacity at 1 at the end */
    transform: scale(1); /* Return to original scale at the end */
  }
}

.confirmation .exitIntentPopup h2 {
  color: var(--deep-blue-green);
  font-size: 28px;
}

.confirmation .exitIntentPopup .closeCross {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 18px;
}

.confirmation .exitIntentCMLogo {
  max-width: 148px;
}

.no-scroll {
  overflow: hidden;
}

.confirmation .circle {
  height: 32px;
  width: 32px;
  margin-right: 16px;
}

@media screen and (min-width: 540px) {
  .confirmation .wrapper .buttons-wrapper {
    flex-flow: row;
    max-width: none;
  }
  .confirmation .wrapper .buttons-wrapper .back-btn {
    order: 1;
    margin-left: 24px;
  }
  .confirmation .wrapper .buttons-wrapper .editInfoBtn {
    order: 0;
    margin-right: 24px;
  }
  .confirmation .content .buttons-wrapper .editInfoBtn {
    width: 130px;
  }
  .confirmation .exitIntentPopup {
    padding: 0px 24px 0px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .confirmation .exitIntentPopup h2 {
    font-size: 34px;
  }
  .confirmation .exitIntentWrapper {
    max-width: 720px;
  }
}

@media screen and (min-width: 768px) {
  .confirmation .wrapper {
    padding: 40px 24px 48px;
  }
  .confirmation .content .buttons-wrapper .editInfoBtn {
    width: 250px;
  }
}

@media screen and (min-width: 810px) {
  .confirmation .topTitle {
    text-align: start;
  }
}

@media screen and (min-width: 960px) {
  .confirmation .topTitle {
    padding: 40px 0;
  }
  .confirmation .wrapper {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1024px) {
  .confirmation .exitIntentDesktopBackground {
    display: flex !important;
    max-width: 50%;
  }

  .confirmation .exitIntentWrapper {
    display: flex;
    max-width: none;
    align-items: center;
  }

  .confirmation .exitIntentPopup {
    padding: 0px 40px 0px 40px;
  }

  .confirmation .exitIntentPopupBody {
    margin-left: -60px;
  }
}

@media screen and (min-width: 1120px) {
  .confirmation .exitIntentPopup h2 {
    font-size: 48px;
  }

  .confirmation .exitIntentPopup h5 {
    font-size: 22px;
  }

  .confirmation .exitIntentPopup {
    padding: 0px 60px 0px 60px;
  }
}

@media screen and (min-width: 1280px) {
  .confirmation .exitIntentWrapper {
    max-width: 1160px;
  }
}