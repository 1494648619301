.quizQuestionBox {
    background: #FFFFFF;
    
    border: 1px solid #FFE4AF;
    box-sizing: border-box;
    
    box-shadow: 6.87714px 13.7874px 25.4815px rgba(0, 0, 0, 0.0425185), 1.45478px 2.91657px 6.51852px rgba(0, 0, 0, 0.0274815);
    border-radius: 3px;
    max-width: 600px;

    padding: 40px 20px;

    margin: 0 auto;
    margin-top: 20px;
    min-height: 238px;
}

.contactInfoPad {
    padding: 40px 20px;
}

.quizQuestionBox .quizBtn {
    color: var(--deep-blue-green);
    border: 2px solid var(--deep-blue-green);
    box-sizing: border-box;
    border-radius: 2px;
}

.quizQuestionBox .quizBtn:hover {
    background: var(--lightest-blue-green);
}

.quizQuestionBox .quizBtn h5 {
    margin-bottom: 0px;
}

.quizQuestionBox .quizBtn.genderSize {
    width: 126px;
    height: 44px;
}

.quizQuestionBox .quizBtn.tobaccoSize {
    width: 96px;
    height: 44px;
}

.quizQuestionBox .quizBtn.selected {
    background: var(--deep-blue-green);
    color: #FFFFFF;
}

.quizQuestionBox .selectedCheck {
    width: 20px;
    height: 20px;
}

.quizDateSelect select{
    width: 100%;
    height: 36px;
}

.quizDateSelect .px-date {
    padding-left: 6px;
    padding-right: 6px;
}

.quizStateSelect .dd-wrapper,
.quizCoverageSelect .dd-wrapper  {
    width: 100%;
    max-width: 400px;
    height: 36px;
    margin: 0 auto;
}

.quizQuestionBox .tobaccoLabel {
    max-width: 371px;
}

.quizContactInformation {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.quizContactInformation .quoteBtn {
    margin-top: 1rem;
}

.quizContactInformation input {
    height: 36px;
}

.quizDateSelect .dobSelect {
  padding-left: 3px;
  padding-right: 3px;
}

.quizContactInformation input:focus {
  border-color: var(--light-blue-green) !important;
}

.c-bluegreen-dp {
  color: var(--deep-blue-green);
}

.fs-12 {
  font-size: 12px;
}

.quizContactInformation .error {
  font-size: 11.5px;
}

.quizContactInformation .errWrapper {
  height: 20.09px;
  line-height: 13px;
}

.quizTwoOptionsSelect h5.not-mac-os {
  margin-top: 2px;
}

@-moz-document url-prefix() {
  .quizTwoOptionsSelect h5 {
    margin-top: 2px;
  }
}

@media screen and (min-width: 540px){
  .quizDateSelect .dobSelect {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  }

  .quizQuestionBox {
    margin-top: 28px;
  }

  .c-bluegreen-dp {
    font-size: 17px;
  }
}


/* SLIDE ANIMATIONS */
.details .slideLeft,
.childrenApplication .slideLeft {
    transform: translateX(-1500px);
    transition: all 0.5s ease-in-out;
  }
  
  .details .slideNextQAppearence,
  .childrenApplication .slideNextQAppearence {
    animation: rightToCenter ease-in-out 0.5s;
  }
  
  @keyframes rightToCenter {
    0% {
      transform: translateX(1500px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  .details .slidePrevQAppearence,
  .childrenApplication .slidePrevQAppearence {
    animation: leftToCenter ease-in-out 0.5s;
  }
  
  @keyframes leftToCenter {
    0% {
      transform: translateX(-1500px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  
  .details .slideRight,
  .childrenApplication .slideRight {
    transform: translateX(1500px);
    transition: all 0.5s ease-in-out;
  }
  
  @media screen and (min-width: 520px){
    .contactInfoPad {
      padding: 80px 40px;
    }
  }