.loaderWrapper {
  height: 100vh;
}

.loader {
  height: calc(80vh);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loader p {
  font-size: 16px;
}

.loader img {
  max-height: 100%;
  width: 30%;
}
