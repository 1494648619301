.next-btn {
  height: 32pt;
  font-size: 17px;
  font-weight: 600;
  background: rgb(202, 145, 28);
}
.back-btn {
  background: var(--goldenish-yellow);
  color: var(--deep-grey);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 26px;

  position: static;
  width: 278px;
  height: 58px;
  margin: 0 auto;
  font-size: 17px;
  font-weight: 700;
  border-radius: 1px;
}
.back-btn:hover {
  transition: all ease-in-out .2s;
  background: var(--button-background-yellow);
}
.fixed-btn {
  position: absolute;
  font-weight: 300;
  color: #DEF1F1;
  z-index: 1;
  top: 134px;
}
.fixed-btn.mt-3 {
  margin-top: 16px;
}
.fixed-btn img {
  width: 14px;
  height: 14px;
  margin-right: 9.3px;
  transition: all ease-in-out .2s;
}
.fixed-btn-mobile img {
  width: 30px;
  position: fixed;
  bottom: 20px;
  left: 5%;
  z-index: 99;
}

.fixed-btn .editInformation {
  line-height: 18.2px;
  padding-top: 2px;
  font-family: "aktiv-grotesk";
  font-weight: 400;
}

.fixed-btn:hover img {
  margin-right: 13.3px;
  transition: all ease-in-out .2s;
}

.fixed-btn:hover {
  opacity: 0.7;
}

.submit-btn {
  background: var(--goldenish-yellow);
  color: var(--deep-grey);
  margin-top: 22px;
  font-family: 'aktiv-grotesk';
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.01em;
  padding: 18px 0;
  border-radius: 1px;
}
.submit-btn:hover {
  background: var(--button-background-yellow);
}
.close-btn {
  position: absolute;
  right: 20px;
  top: 100px;
  font-size: 14px;
  font-weight: 300;
  color: rgb(202, 145, 28);
  border: 1px solid rgb(202, 145, 28);
  border-radius: 50%;
  transition: all ease 300ms;
}
.close-btn-mobile {
  position: fixed;
  bottom: 20px;
  left: 85%;
  z-index: 99;
  font-size: 14px;
  font-weight: 300;
  color: rgb(202, 145, 28);
  border: 1px solid rgb(202, 145, 28);
  border-radius: 50%;
  transition: all ease 300ms;
}
.close-btn:hover,
.close-btn-mobile:hover {
  color: red;
  border-color: red;
  transform: rotate(180deg);
}

.editInfoBtn {
  color: var(--deep-blue-green);
  margin-top: 18px;
  font-weight: bold;
  text-decoration: none;
}

.editInfoBtn:focus,
.editInfoBtn:hover {
  text-decoration: none;
  color: var(--deep-blue-green);
}

.editInfoBtn:hover {
  opacity: 0.7;
}

.editInfoBtn img {
  margin-right: 8px;
  transition: all ease-in-out .2s;
}

.editInfoBtn:hover img {
  margin-right: 13.3px;
  transition: all ease-in-out .2s;
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .editInformationContainer,
  .backToQuotesContainer {
    min-width: 180px;
  }
}}

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm){ 
@supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
  .editInformationContainer,
  .backToQuotesContainer  {
    min-width: 180px;
  }
}}

/* Safari 6.1-10.0 (but not 10.1) */
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
@media {
  .editInformationContainer,
  .backToQuotesContainer  {
    min-width: 180px;
  }
}}

@media screen and (max-width: 350px) {
  .back-btn {
    height: 30px;
    font-size: 10px;
  }
  .next-btn {
    height: 30px;
    font-size: 12px;
  }
}

@media screen and (min-width: 540px) {
  .editInfoBtn {
    margin-top: 0px;
  }
}

@media screen and (min-width: 768px) {
  .close-btn-mobile {
    left: 90%;
  }
}

@media screen and (min-width: 960px) {
   /* Safari 11+ */
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .editInformationContainer,
    .backToQuotesContainer {
      min-width: 215px;
    }
  }}

  /* Safari 10.1 */
  @media not all and (min-resolution:.001dpcm){ 
  @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    .editInformationContainer,
    .backToQuotesContainer {
      min-width: 215px;
    }
  }}

  /* Safari 6.1-10.0 (but not 10.1) */
  @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
  @media {
    .editInformationContainer,
    .backToQuotesContainer {
      min-width: 215px;
    }
  }}
}

@media screen and (orientation: landscape) {
  .close-btn-mobile {
    left: 90%;
  }
}

@media only screen and (min-width: 648px) {
  .editInformationContainer {
    top: inherit;
  }
  .fixed-btn {
    top: 65px
  }
}

@media only screen and (min-width: 767px) {
  .fixed-btn {
    top: 80px;
  }
}