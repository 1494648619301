.dd-wrapper {
    border-radius: 2px;
    width: 100%;
    position: relative;
}

.dd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0.375rem 1.875rem 0.375rem 12px;
    background-color: #fff;
    border: 1px solid var(--med-light-grey);
    border-radius: 2px;
    width: 100%;
    line-height: 1em;
    height: 36px;
    text-align: left;
    color: var(--deep-grey);
}

.dd-header span {
    display: flex;
    margin-right: 20px;
}

.dd-header:hover {
    border-color: #767676;
}

.dd-header-title {
   font-size: 12px;
   text-align: left;
   display: inline-block;
   overflow: hidden;
   white-space: nowrap;
}

.dd-wrapper .dd-list {
    width: 100%;
    border-radius: 2px;
    margin-top: -3px!important;
    font-size: 14px;
    min-width: 0;
    padding: 0;
    border: 1px solid var(--light-blue-green);
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    background-clip: padding-box;
}

.dd-wrapper .dd-list .dd-scroll-list {
    max-height: 225px;
    overflow: auto;
    overflow-x: hidden;
}

.dd-wrapper .dd-list .dd-scroll-list .dd-list-item  { 
    display: block;
    width: 100%;
    padding: 0.5rem 12px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}

.dd-header.show {
    border-color: var(--light-blue-green);
}

.dd-wrapper .dd-list .dd-scroll-list .dd-list-item:hover {
    background-color: var(--lightest-blue-green);
}


.dd-header:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}

.dd-header:after {
    background-image: url('./arrowDown.svg');
    border: 0;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 15px;
    background-size: cover;
    margin-top: 1px;
    transform: rotate(0);
}

.dd-header.show:after {
    transform: rotate(180deg);
}

.dd-header-title.not-mac-os {
    margin-top: 2px;
}

@media screen and (min-width: 540px){
    .dd-header-title {
        font-size: 14px;
     }
}