$grid-breakpoints: (
  xs: 0px,
  sm: 532px,
  md: 648px,
  mdxl: 810px,
  lg: 960px,
  lgxl: 1120px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1600px
);

$container-max-widths: (
  xs: 348px,
  sm: 508px,
  md: 720px,
  lg: 880px,
  xl: 1200px,
  xxl: 1360px,
  xxxl: 1520px
);

@import './../../node_modules/bootstrap/scss/bootstrap.scss';