.topTitle {
  transition: all ease-in-out .2s;
  background-color: var(--top-title-background);
  color: #FFFFFF;
  height: 246px;
  padding-top: 64px;
  padding-bottom: 24px;
}

.stickyBanner {
  height: auto;
  background-color: var(--yellow-cream);
}

.titleBody u {
  cursor: pointer;
}

.titleBody .changeAmount {
  font-family: "aktiv-grotesk";
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;

  position: static;
  width: 158px;
  min-width: 158px;
  height: 42px;
  left: 0px;
  top: 60px;

  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 1px;
  font-size: 17px;
  margin-top: 8px;
}

.titleBody .changeAmount:hover,
.stickyChangeAmount .changeAmountStickyBtn:hover {
  opacity: 0.7;
  transition: all ease-in-out .2s;
}

.titleBody h5 {
  margin-top: 8px;
}

.no-quotes p strong {
  font-weight: bold;
}

.quote {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--button-background-yellow);
  padding: 16px 16px 20px;
  border-radius: 3px;
  background: #FFFFFF;
}

.card {
  border: 1px solid var(--button-background-yellow);
  border-radius: 3px;
}

.quote div .icon {
  width: 15px;
  margin-right: 4px;
}

.quote div .infoIcon {
  margin-right: 0px;
  width: 16px;
  padding-top: 1px;
  cursor: default;
}

.quote div h5 {
  margin-bottom: 0px;
}

.quote div h5.immediate {
  color: var(--deep-blue-green);
}

.quote div h5.guaranteed {
  color: var(--bright-peachy);
}

.quote div h5.easy {
  color: var(--periwinkle);
}

.quote div h5 .colorText {
  color: var(--text);
}

.card .card-header .immediateImg {
  width: 23.32px;
}

.card .card-header .giImg {
  width: 21px;
}

.card .card-header {
  background: var(--deep-grey);
  height: 38px;
  border-radius: 3px 3px 0px 0px;
  padding: 8px;
  font-size: 17px;
  font-weight: 500;
  line-height: 130%;
  align-items: center;
}
.card .card-body {
  background: var(--card-background);
}
.card .card-body .featured {
  position: absolute;
  top: 51px;
  left: -13px;
  z-index: 1;
}
.card .card-body .company-img {
  min-width: 120%;
}
.raiting {
  color: var(--deep-grey);
}

.quotesBackground {
  background: var(--quotes-background);
}

.mt-featured {
  margin-top: 2.3rem;
}

.mr4 {
  margin-right: 4px;
}

.card .download,
.quote .download {
  color: var(--deep-blue-green);
  margin-left: 0.5rem;
}

.downloadContainer {
  transition: all ease-in-out .2s;
}


.downloadContainer:hover {
  opacity: 0.7;
}

.quote .featuredCovOptions,
.card .featuredCovOptions {
  margin-right: 3px;
}

.mt-05 {
  margin-top: 0.5rem;
}

.p-1rem {
  padding: 1rem 1rem 1rem;
}

.stickyBanner {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  padding-top: 0;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
}

.stickyBanner.sticky-banner-show {
  display: flex;
}

.sidebarContainer {
  height: calc(100vh - 1rem);
  overflow-y: auto; 
}

.stickyBanner.banner-was-displayed.sticky-banner-hide {
  display: none;
}

.featuredRow .cardWrapper:not(:first-child) {
  margin-top: 36px;
}

@keyframes decreaseMargin {
  0% {
    margin-top: 108px;
  }
  100% {
    margin-top: 2rem;
  }
}

.stickyBanner .content {
  width: 100%;
}

.stickyBanner .yourQuotes {
  display: none;
}

.stickyBanner .changeAmount {
  margin: 0;
}

.stickyBack {
  display: none !important;
}

.amountRow {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.stickyBanner .amountRow {
  justify-content: center;
  flex-flow: row;
}

.amountRow .sticky-amount-text {
  display: none;
}

.stickyBanner .amountRow h5 {
  display: none;
}

.stickyBanner .amountRow .sticky-amount-text {
  display: block;
  margin: 0 14px 0 0;
}

.divider {
  border-top: 1px solid #D6D6D6;
  width: 100%;
}

.competitor .divider {
  margin-top: 20px;
  margin-bottom: 12px;
}

.horizontal-divider {
  border-left: 1px solid #D6D6D6;
  height: auto;
  margin: 0 15px;
}

.competitors {
  display: flex;
  flex-flow: column;
  padding: 40px 0;
  background-color: white;
  align-items: center;
}

.competitors .title {
  margin: 0 16px;
  font-feature-settings: 'liga' off;
  max-width: 1000px;
}

.competitors .row {
  margin: 0;
  max-width: 1200px;
}
.competitors .col-12 {
  padding: 0;
}

.competitors h3 {
  margin-bottom: 0;
}

.competitor {
  background-color: var(--lightest-grey);
  margin: 24px 16px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 22px 16px 20px;
  border: 1px solid var(--light-grey);
  border-radius: 3px;
}

.competitor .plan {
  margin-bottom: 2px;
}

.competitor .image-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

.competitor .lincoln-heritage-wrapper {
  min-height: 38px;
}

.competitor .lincoln-heritage {
  height: 37px;
}

.competitor .aarp-wrapper {
  min-height: 38px;
}

.competitor .aarp {
  height: 35px;
}

.competitor .aaa-wrapper {
  min-height: 58px;
  margin-bottom: 16px;
}

.competitor .aaa {
  height: 57px;
}

.competitor .ethos-wrapper {
  min-height: 58px;
  margin-bottom: 16px;
}

.competitor .ethos {
  height: 28px;
}

.competitor .colonial-penn-wrapper {
  min-height: 49px;
}

.competitor .colonial-penn {
  height: 49px;
}

.competitor .lumico-wrapper {
  min-height: 50px;
}

.competitor .lumico {
  height: 50px;
}

.competitor .trustage-wrapper {
  min-height: 50px;
}

.competitor .trustage {
  height: 50px;
}

.competitor a {
  padding: 2px 0;
}

.competitor a img {
  height: 18px;
  width: 18px;
  margin-left: 8px;
}

.competitor .competitor-quote {
  margin: 16px 0;
}

.competitor .quote-text {
  font-family: 'aktiv-grotesk', sans-serif;
  font-weight: 500;
  color: var(--bright-peachy);
  margin: 16px 0;
}

.competitor .quote-text.colonial-penn-coverage {
  margin: 0;
  font-weight: normal;
}

.competitor .quote-value {
  font-feature-settings: 'liga' off;
  color: var(--bright-peachy);
}

.pt-12rem {
  padding-top: 12rem;
}

.bestQuote.divider {
  margin: 1.5rem auto;
}

.featuredRow .quoteBtn,
.quote .quoteBtn {
  margin-bottom: 0 !important;
}

.card h3 {
  margin-bottom: 0px;
}

.benefitsWrapper {
  max-width: 508px;
  margin: 0 auto 1.5rem;
}

.myQuote {
  margin: 0.75rem 0;
}

.quoteCard.divider {
  margin: 24px auto;
}

.mt-xs-05 {
  margin-top: 0.5rem;
}

.stickyChangeAmount {
  right: 40px;
  bottom: 24px;
  max-width: 230px;
}

.stickyChangeAmount .coverageLabel {
  margin-top: 12px;
  margin-bottom: 6px;
}

.stickyChangeAmount b{
  font-weight: 500;
}

.stickyChangeAmount .changeAmountStickyBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;

  border: 1px solid var(--deep-grey);
  border-radius: 1px;

  height: 42px;
  width: 156px;

  margin: 12px auto;
}

.priceWrapper {
  padding-right: 8px !important;
}

.card .logo,
.quote .logo {
  display: block;
  width: 70px;
  height: 70px;
}

.Gerber.gerber-baby {
  width: 35%;
}
.Gerber.gerber-text {
  width: 65%;
}

.quote .lh-26 {
  line-height: 26px;
}

.deepGray,
.deepGray {
  color: var(--deep-grey);
}

.quote .fs-26,
.card .fs-26 {
  font-size: 26px;
}

.quote .fs-18,
.card .fs-18 {
  font-size: 18px;
}

.quote .fs-15 {
  font-size: 15px;
}

.quote.notAvailable {
  background: var(--lighter-grey);
  border: none;
}

.quote .fw-500 {
  font-weight: 500;
}

.quote .breakWord {
  line-height: 19.5px;
  margin-bottom: 2px;
}


.card .benefit-text {
  color: var(--text);
  margin-bottom: 0;
}

.planTypeSwitcherWrapper {
  margin-top: 6px;
  margin-bottom: 0;
  gap: 8px;
}

.planTypeSwitcher {
  width: 68px;
  height: 30px;
  border: 1px solid #767676 !important;
}

.planTypeSwitcherWrapper .active {
  color: var(--deep-blue-green);
  font-weight: 500;
}

.planTypeSwitcherWrapper span {
  font-size: 15px;
}

.planTypeSwitcher:focus {
  outline: none;
}

.editCoverageLinkContainer {
  border-bottom: 1px solid var(--deep-grey);
}

.editCoverageLinkContainer:hover {
  color: var(--text) !important;
}

.howDoThesePlansWork {
  padding: 12px 16px;
  color: var(--deep-blue-green);
  background-color: var(--lightest-blue-green);
  margin: 60px 0;
  cursor: pointer;
  border-radius: 4px;
}

.howDoThesePlansWork:hover {
  background-color: var(--hovered-blue-green);
}

.howDoThesePlansWork.term-life {
  margin: 60px 0 48px;
}

.policyTypeWrapper {
  margin-top: 40px;
}

.quote .cpEquation {
  max-width: 189px;
}

.quote .ellipse  {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: var(--med-light-grey);
}

.quotesListParent {
  margin: 20px 0 80px;
}

.ratingWrapper p{
  margin-bottom: 4px;
}

.quotes-list-header {
  margin-bottom: 12px;
}
.topTitle {
  padding-top: 64px;
}

.h-34 {
  height: 34px;
}

.lh-34 {
  line-height: 34px;
}

.lh-38 {
  line-height: 38px;
}

.lh-40 {
  line-height: 40px;
}

/* FIREFOX */
@-moz-document url-prefix() {
  .lh-38 {
    line-height: 40px;
  }
}

.macMoMargin {
  margin-bottom: 3px !important;
}

/* RESPONSIVE */
@media screen and (max-width: 350px) {
  .quotes div .btn-link {
    font-size: 14px;
  }
  .quotes-list-header p.title {
    font-size: 12px;
  }
  .quotes-list-header div p {
    font-size: 12px;
  }
  .quote div .quote-benefit {
    font-size: 13px;
  }
  .quote .btn {
    font-size: 12px;
    margin-left: 16px;
  }
  .quote .price {
    font-size: 20px;
  }
}

@media screen and (min-width: 359px) {
  .quote .breakWord {
    flex-direction: row !important;
    gap: 3px;
  }
}

@media screen and (min-width: 385px) {
  .quote .breakWordWithInfoIcon {
    flex-direction: row !important;
    gap: 3px;
  }
}

@media screen and (min-width: 360px) {
  .d-sm-block-cp {
    display: block !important;
  }

  .flex-sm-row-cp {
    flex-direction: row !important;
  }
}



@media screen and (min-width: 648px) {
  .card .card-body .company-img {
    min-width: 110%;
  }
  .competitors .title {
    margin: 0 24px;
  }
  .competitors .row {
    padding: 0 12px;
  }
  .competitors .row > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  .competitor {
    margin: 24px 12px 0;
    height: 100%;
  }
  .competitor .colonial-penn-wrapper {
    min-height: 50px;
  }

  .cardWrapper:last-child {
    margin-top: 0px !important;
  }

  .cardWrapper.unique:first-child {
    margin-top: 0px !important;
    padding-left: 0 !important;
    padding-right: 0px !important;
  }
  
  .cardWrapper:last-child {
    margin-top: 0px !important;
    padding-left: 18px !important;
  }

  .cardWrapper:first-child {
    padding-right: 18px !important;
  }

  .quotes div .btn-link {
    color: rgb(202, 145, 28);
    font-size: 20px;
  }
  .quotes-list-header p.title {
    font-size: 12pt;
    width: auto;
  }
  .quotes-list-header .sort div {
    width: auto;
  }
  .quote div .quote-benefit {
    font-size: 17px;
  }
  .quote div .raiting-msg {
    font-size: 13px;
  }

  .priceWrapper {
    padding-right: 0px !important;
  }
  .howDoThesePlansWork.term-life {
    margin: 40px 0 0;
  }
}

@media screen and (max-width: 750px) and (orientation: landscape) {
  .quotes-list-header .sort {
    flex-wrap: nowrap;
  }
  .quotes-list-header p.title {
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .titlesContainer {
    margin-top: 10px;
  }
}



.approval-required {
  font-size: .7em;
}

@media screen and (min-width: 532px){
  .stickyBanner .content {
    padding-left: 16px;
    padding-right: 16px;
  }

  @keyframes decreaseMargin {
    0% {
      margin-top: 108px;
    }
    100% {
      margin-top: 2.5rem;
    }
  }

  .changeAmount {
    margin: 0 auto;
  }

  .titleBody h2 {
    margin-bottom: 0.6rem;
  }

  .topTitle {
    height: 220px;
  }

  .stickyBanner {
    padding-top: 16px;
    height: auto;
  }

  .applyRow {
    margin-top: 1rem;
  }

  .card .card-body .company-img {
    min-width: 100%;
    max-height: 36px;
  }

  .card .card-header .immediateImg {
    width: 17.36px;
  }

  .card .card-header .giImg {
    width: 16px;
  }

  .card .card-header h5 {
    margin-left: 4px !important;
    font-size: 16px !important;
  }

  .quote {
    padding: 16px 16px 18px;
  }
  
  .quote .flex-sm-wrap {
    width: 100%;
  }

  .quote .quoteBtn {
    margin-top: 0px;
  }

  .mt-xs-05 {
    margin-top: 0px;
  }

  .quote:last-child {
    margin-bottom: 40px;
  }

  .card .card-body .featured {
    top: 51px;
  }

  .logoRatingWrapper {
    margin-top: 42px;
  }

  .card .card-body {
    padding: 0 16px 24px;
  }

  .AmericanAmicable {
    width: 100%;
    height: 100%;
  }

  .ratingWrapper {
    width: 100%;
    height: 20px;
  }

  .quote .smPrice {
    margin-top: 60px;
  }

  .quote .smPrice.gtlPrice {
    margin-top: 43px;
  }

  .quote .smPrice.cpSmPrice {
    margin-top: 23px;
  }

  .quoteCard.divider {
    margin: 24px auto;
  }

  .quote .AarpTLCovRange {
    position: absolute;
    max-width: 102%;
    width: 102%;
  }

  .cardWrapper:last-child {
    margin-top: 36px;
  }

  .quote .cpEquation {
    margin-right: 20px !important;
  }
  
  .quotes-list .quoteCard.divider {
    margin: 20px auto;
  }

  .quoteCard .wleftcol-sm-override {
    flex: 0 0 53%;
    max-width: 53%;
  }

  .quoteCard .wrightcol-sm-override {
    flex: 0 0 47%;
    max-width: 47%;
  }
}

@media screen and (min-width: 648px){
  .amountRow {
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 572px;
    margin: 0 auto 0;
  }

  .stickyBanner .amountRow {
    margin-top: 0;
  }

  .changeAmount {
    margin-top: 0 !important;
    margin-left: 20px;
  }

  .stickyBanner {
    height: auto;
    padding-top: 16px;
  }

  .card .card-header .immediateImg {
    width: 23.32px;
  }

  .card .card-header .giImg {
    width: 21px;
  }

  .featuredCovOptions {
    margin-right: 3px;
  }

  .benefitsWrapper {
    max-width: 100%;
  }

  .card .card-header h5 {
    font-size: 17px !important;
  }

  .card .card-header h5 {
    margin-left: 0.5rem !important;
  }

  .quote {
    padding: 24px 16px;
  }

  .pricePlusApply {
    margin-top: 0.8rem;
  }

  .quote:last-child {
    margin-bottom: 60px;
  }

  .horizontal-divider {
    margin: 0 23px;
  }

  .topTitle {
    height: 194px;
  }

  .quoteCard .wleftcol-sm-override {
    flex: inherit;
    max-width: inherit;
  }

  .quoteCard .wrightcol-sm-override {
    flex: inherit;
    max-width: inherit;
  }
}

@media screen and (min-width: 810px){
  .cardWrapper:nth-child(n+2) {
    margin-top: 36px !important;
  }

  .card {
    max-width: 100%;
  }

  .cardWrapper:last-child {
    padding-left: 0 !important;
  }

  .cardWrapper:first-child {
    padding-right: 0 !important;
  }
  
  .sticky-wrapper {
    display: flex;
    flex-flow: column;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    top: 0;
    padding-bottom: 50px;
  }
  .quotesListParent {
    margin-top: 40px;
  }
  .quotes-list-header {
    margin-top: 60px;
  }

  .sidebarContainer {
    padding-left: 12px;
    padding-right: 24px;
  }

  .quoteCard .wleftcol-sm-override {
    flex: 0 0 53%;
    max-width: 53%;
  }

  .quoteCard .wrightcol-sm-override {
    flex: 0 0 47%;
    max-width: 47%;
  }
}

@media screen and (min-width: 960px){
  .competitors {
    padding: 60px 0;
  }
  .competitors .title {
    margin: 0 40px;
  }
  .competitors .row {
    padding: 0 28px;
  }
  .benefits p {
    letter-spacing: normal;
  }

  .quote h5 {
    font-size: 16px;
  }

  .pricePlusApply {
    padding-right: 55.2px;
  }

  .card.unique {
    max-width: 100%;
  }

  .col-lg-4.cardWrapper {
    flex: 0 0 35.33333%;
    max-width: 35.33333%;
  }

  .quotesListParent {
    padding-left: 40px;
    padding-right: 20px;
  }

  .quotes-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    max-width: 1119px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /* Safari 11+ */
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .benefits {
      max-width: 222px;
    }
  }}

  /* Safari 10.1 */
  @media not all and (min-resolution:.001dpcm){ 
  @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    .benefits {
      max-width: 222px;
    }
  }}

  /* Safari 6.1-10.0 (but not 10.1) */
  @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
  @media {
    .benefits {
      max-width: 222px;
    }
  }}

  .stickyParent {
    flex: 0 0 290px;
  }

  .content.expandLg {
    flex: 1 1 auto;
    max-width: none;
  }

  .planType {
    padding-left: 0 !important;
  }

  .card .card-header h5 {
    font-size: 17px !important;
  }
  
  .cardWrapper.unique:first-child {
    margin-top: 0px !important;
    padding-left: 0 !important;
    padding-right: 0px !important;
  }
  
  .cardWrapper:last-child {
    margin-top: 0px !important;
    padding-left: 18px !important;
  }

  .cardWrapper:first-child {
    padding-right: 18px !important;
  }

  .sidebarContainer {
    padding-left: 20px;
    padding-right: 40px;
  }

  .quoteCard .wleftcol-sm-override {
    flex: inherit;
    max-width: inherit;
  }

  .quoteCard .wrightcol-sm-override {
    flex: inherit;
    max-width: inherit;
  }
}

@media screen and (min-width: 1120px){
  .card .card-body {
    padding: 0px 16px 24px;
  }

  .card .ratingLbl {
    align-self: center;
  }

  .competitors {
    padding: 80px 0 84px;
  }
  .competitors .title {
    margin-bottom: 24px;
  }
  .competitor .lincoln-heritage {
    height: 43px;
  }
  .competitor .lincoln-heritage-wrapper {
    min-height: 50px;
  }
  .competitor .aarp {
    height: 41px;
  }
  .competitor .aarp-wrapper {
    min-height: 50px;
  }
  .competitor .aaa {
    height: 70px;
  }
  .competitor .aaa-wrapper {
    min-height: 70px;
  }
  .competitor .ethos {
    height: 32px;
  }
  .competitor .ethos-wrapper {
    min-height: 70px;
  }
  .competitor .colonial-penn {
    height: 60px;
  }
  .competitor .colonial-penn-wrapper {
    min-height: 60px;
  }

  .card .card-body .featured {
    top: 51px;
  }
  
  .quote .quoteBtn {
    margin-top: 0rem !important;
  }
}

@media screen and (min-width: 1280px){
  .amountRow {
    width: 580px;
  }
  
  .topTitle {
    height: 207px;
  }
  
  .quote h5 {
    font-size: 17px;
  }
  
  .card {
    max-width: none;
  }

  .px-xl-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .featuredRow {
    margin-right: 80.5px !important;
    margin-left: 80.5px !important;
  }

  .featuredRow {
    max-width: 1119px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .competitors {
    padding-bottom: 104px;
  }
  .competitor {
    margin: 24px 18px 0;
  }
  .competitors .row {
    max-width: 1212px;
  }

  .quotesListParent {
    padding-right: 30px !important;
    margin-left: 80px;
    margin-top: 48px;
    margin-bottom: 60px;
    padding-left: 0px !important;
  }

  .content.expandLg {
    max-width: 860px;
  }

  .stickyParent {
    flex-basis: 340px;
  }

  .sidebarContainer {
    padding-left: 30px;
    padding-right: 80px;
  }
}
