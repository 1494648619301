.benefits {
  padding: 8px 16px;
  flex: 1 0;
}

.submit-benefits {
  padding: 6px 0;
}

.benefits h3 {
  font-size: 18px;
}
.benefits p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
  letter-spacing: 0.01em;
}

.benefits .divider {
  width: 100%;
  border: 1px solid var(--light-grey);
}

.mtBenefits {
  margin-top: 28px;
}

.mt-15 {
  margin-top: 1.5rem;
}

.benefits h5 {
  margin-bottom: 0.75rem;
}

.benefitsWrapper {
  margin-bottom: 1.5rem;
}

.benefitsWrapper p  {
  margin-top: 1px;
}
.benefitsWrapper .not-mac-os {
  margin-top: 2px;
}

.mb-075 {
  margin-bottom: 0.75rem;
}

.benefitsListContainer {
  margin-top: 12px;
}

.benefitsListWrapper {
  padding: 28px 0 0;
}

.seeMore {
  cursor: pointer;
}

.seeMore span {
  font-size: 13px;
  color: var(--medium-gray);
}

.seeMore:hover span {
  color: var(--deep-grey);
}

.seeMore img {
  transition: transform 0.3s ease-in-out;
}

.seeMore.expanded img {
  transform: rotate(-180deg);
}


.seeMoreContent {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

.seeMoreContent.expanded {
  max-height: 146px;
  opacity: 1;
}

.seeMoreContent.expanded.term_life {
  max-height: 280px;
} 


.benefitsColsWrapper {
  flex-flow: column;
}

.benefitsCols .my-custom-last,
.benefitsCols .my-custom-first {
  margin: 0.25rem 0;
}

.sticky-wrapper .divider {
  margin: 28px 0;
}

.sticky-wrapper .mtBenefits {
  margin-top: 0;
}

.sticky-wrapper .benefits {
  padding: 0;
}

.sticky-wrapper .benefitsListWrapper{
  padding-top: 0;
}

@-moz-document url-prefix() {


  .seeMore img {
    margin-bottom: 1.5px !important;
  }

}

@media screen and (max-width: 350px) {
  .benefits h3 {
    font-size: 16px;
  }
  
  .benefits p {
    font-size: 12px;
  }
}

/* Submit card breakpoint*/
@media screen and (min-width: 513px) {
  .benefitsColsWrapper {
    flex-flow: row;
  }
  .benefitsCols {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .benefitsCols .my-custom-last {
    margin: 0.25rem 0 0;
  }
  .benefitsCols .my-custom-first {
    margin: 0 0 0.25rem;
  }
}

@media screen and (min-width: 753px) {
  .benefitsColsWrapper {
    flex-flow: column;
    justify-content: space-between;
  }
  .benefitsColsWrapper.term-life {
    flex: none;
  }
  .benefitsColsWrapper,
  .benefitsListContainer,
  .benefitsListWrapper {
    flex: 1 0;
  }
  .benefitsCols {
    flex: 1 0;
    max-width: 100%;
    justify-content: space-between;
  }
  .benefitsColsWrapper.term-life .benefitsCols {
    justify-content: unset;
  }
  .benefitsColsWrapper.term-life .my-custom-last {
    margin: 20px 0;
  }
  .benefitsColsWrapper.term-life .my-custom-first {
    margin: 4px 0 20px;
  }
  .benefitsCols .my-custom-last,
  .benefitsCols .my-custom-first {
    margin: 0.25rem 0;
  }
}

@media screen and (min-width: 768px) {
  .medExamCol {
    margin-bottom: 0;
  }

  .mUpfrontImg {
    margin-left: 1.5rem;
  }
}

@media screen and (min-width: 810px) {
  .sticky-wrapper .benefitsColsWrapper.term-life {
    margin-top: 16px;
  }

  .sticky-wrapper .benefitsColsWrapper.term-life .my-custom-first,
  .sticky-wrapper .benefitsColsWrapper.term-life .my-custom-last {
    margin: 4px 0;
  }
}

@media screen and (min-width: 960px) {
  .benefitsWrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .medExamCol p {
    margin-right: 0px;
  }

  .medExamCol {
    margin-bottom: 0.75rem
  }

  .benefits {
    padding-right: 0px !important;
  }

  .mUpfrontImg {
    margin-left: 0rem;
  }
}

@media screen and (min-width: 1080px) {
  .info-box .benefitsColsWrapper {
    flex-flow: row;
  }
  .info-box .benefitsCols .my-custom-last {
    margin: 0.25rem 0 0;
  }
  .info-box .benefitsCols .my-custom-first {
    margin: 0 0 0.25rem;
  }
  .info-box .benefitsColsWrapper.term-life .my-custom-last {
    margin: 4px 0 0;
  }
  .info-box .benefitsColsWrapper.term-life .my-custom-first {
    margin: 0 0 4px 0;
  }
}

@media screen and (min-width: 1280px) {
  .policyTypeWrapper {
    margin-top: 48px !important;
  }
}

@media screen and (min-width: 1660px) {
  .benefitsColsWrapper {
    flex-flow: column;
    justify-content: space-between;
  }
  .benefitsColsWrapper.term-life {
    flex: none;
  }
  .benefitsColsWrapper,
  .benefitsListContainer,
  .benefitsListWrapper {
    flex: 1 0;
  }
  .benefitsCols {
    flex: 1 0;
    max-width: 100%;
    justify-content: space-between;
  }
  .benefitsColsWrapper.term-life .benefitsCols {
    justify-content: unset;
  }
  .benefitsColsWrapper.term-life .my-custom-last {
    margin: 20px 0;
  }
  .benefitsColsWrapper.term-life .my-custom-first {
    margin: 4px 0 20px;
  }
  .benefitsCols .my-custom-last,
  .benefitsCols .my-custom-first {
    margin: 0.25rem 0;
  }
}
